// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* tslint:disable */

export function registerThirdPartyProviders() {
  initMouseFlow();
  initUxLens();
}

function initMouseFlow() {
  window._mfq = window._mfq || [];
  (function () {
    const mf = document.createElement("script");
    mf.type = "text/javascript";
    mf.defer = true;
    mf.src = "//cdn.mouseflow.com/projects/34fc0c21-8e53-46e8-b8b7-51448106b694.js";
    document.getElementsByTagName("head")[0].appendChild(mf);
  })();
}

function initUxLens() {
  (function (w, d, n, u, o, t, m) {
    w["SrecObject"] = o;
    (w[o] =
      w[o] ||
      function () {
        // eslint-disable-next-line prefer-rest-params
        (w[o].q = w[o].q || []).push(arguments);
      }),
      (w[o].l = 1 * new Date());
    (t = d.createElement(n)), (m = d.getElementsByTagName(n)[0]);
    t.async = 1;
    t.src = u;
    m.parentNode.insertBefore(t, m);
  })(window, document, "script", "https://console.uxlens.com/collect/initialize.js", "srec");
  srec("init", "9e292b20-0b2f-11ed-975b-3542a37a842e");
}
