import angular from "angular";
import { Chart, registerables } from "chart.js";
import { registerThirdPartyProviders } from "./admin/scripts/utils/registerThirdPartyServices";
import { AppModule } from "./app.module";

if (import.meta.env.PROD) {
  registerThirdPartyProviders();
}

Chart.register(...registerables);

angular.element(() => angular.bootstrap(document, [AppModule], { strictDi: true }));
