import angular from "angular";
import { LocalDateTime, nativeJs } from "js-joda";
import moment, { Moment } from "moment";
import { DateRangeDefaultOptions } from "../../../../scripts/consts/dateRangeDefaultOptions";
import { CaregiverDict, CaregiverWithDisplayName } from "../../../../scripts/messages/caregiver";
import { CaregiverCertification, CaregiverId } from "../../../../scripts/messages/ids";
import {
  HROnboardingStatistics,
  HROnboardingStatisticsResponse,
} from "../../../../scripts/messages/onboarding_statistics";
import { Api } from "../../../../scripts/services/Api";
import { DatabaseApiService } from "../../../../scripts/services/db";
import { Endpoint } from "../../../../scripts/services/endpoint.service";
import {
  assertDefined,
  chainBooleanFn,
  heapsPermute,
} from "../../../../scripts/utils/generalUtils";

type CaregiverTableDataType = HROnboardingStatistics &
  CaregiverWithDisplayName & { searchName: string[] };

interface CertificationSelect {
  id: number;
  label: CaregiverCertification;
}

interface StageSelect {
  id: number;
  label: string;
}

interface Selectable<T = number> {
  id: T;
}

//! @ngInject
class Controller implements angular.IComponentController {
  static readonly $name = "onboardingComponent";

  private isLoadingCaregivers = true;
  private isLoadingOnboardingData = true;
  private caregiversMap: CaregiverDict = {};
  private caregiverStatistics!: HROnboardingStatistics[];
  private caregiversData?: CaregiverTableDataType[];
  private caregvierStatisticsTable!: NgTableParams<CaregiverTableDataType>;
  private agencyCertifications!: CertificationSelect[];
  private stages!: StageSelect[];
  private filters: {
    certifications: Selectable<number>[];
    freeText: null | string;
    stages: Selectable<number>[];
    dateRange: [Moment, Moment];
  };
  private dateRangeOptions: DateRangeDefaultOptions;

  constructor(
    private $rootScope: angular.IRootScopeService,
    private $scope: ng.IScope,
    private api: Api,
    private endpoint: Endpoint,
    private DatabaseApi: DatabaseApiService,
    private NgTableParams: NgTable.ITableParamsConstructor<CaregiverTableDataType>,
    private dateRangeDefaultOptions: DateRangeDefaultOptions
  ) {
    this.filters = {
      certifications: [],
      freeText: null,
      stages: [],
      dateRange: [moment().subtract(1, "month"), moment()],
    };

    this.dateRangeOptions = {
      ...dateRangeDefaultOptions,
      opens: "right",
    };
  }

  $onInit(): void {
    this.fetchOnboardingData();
    this.fetchCaregivers();
    this.fetchAgencyCertifications();
    this.$rootScope.$on("got_caregivers_data", () => this.fetchCaregivers());
    this.$rootScope.$on("got_agency_certifications", () => this.fetchAgencyCertifications());
  }

  fetchAgencyCertifications = () => {
    this.agencyCertifications = this.DatabaseApi.activeAgencyCertifications().map((val, i) => ({
      id: i,
      label: val.certification,
    }));
  };

  fetchCaregivers = async () => {
    this.caregiversMap = this.DatabaseApi.caregivers();
    this.isLoadingCaregivers = false;
    this.initOnboardingCaregiversTable();
  };

  fetchOnboardingData = async () => {
    const url = this.endpoint({
      path: "agencies/:agencyId/agency_members/:agencyMemberId/hr_caregiver_status",
      params: {
        agencyId: this.$rootScope.agencyId,
        agencyMemberId: this.$rootScope.agencyMemberId,
      },
    });
    const {
      data: { caregivers },
    } = await this.api.get<HROnboardingStatisticsResponse>(url);
    this.caregiverStatistics = caregivers;
    this.isLoadingOnboardingData = false;
    this.initStageSelection();
    this.initOnboardingCaregiversTable();
  };

  initOnboardingCaregiversTable = () => {
    if (this.isLoadingCaregivers || this.isLoadingOnboardingData) {
      return;
    }

    this.caregiversData = this.formatCaregiverRows(this.caregiverStatistics);

    this.applyTableData();
  };

  applyTableData = () => {
    if (this.caregiversData === undefined || this.caregiversData.length === 0) {
      return;
    }
    const options = {
      count: 25,
      sorting: { timeStamp: "desc" },
    };

    this.caregvierStatisticsTable = new this.NgTableParams(options, {
      counts: [10, 25, 50, 100],
      dataset: this.applyFilters(this.caregiversData),
    });
  };

  selectableEvents = {
    onItemSelect: this.applyTableData,
    onItemDeselect: this.applyTableData,
    onDeselectAll: this.applyTableData,
  };

  initStageSelection = () => {
    const stageHash = new Set<string>();
    for (const caregiver of this.caregiverStatistics) {
      stageHash.add(this.hashCaregiverStage(caregiver));
    }
    this.stages = [...stageHash.values()].map((label, i) => ({
      id: i,
      label: label,
    }));
  };

  hashCaregiverStage = (caregiver: HROnboardingStatistics) =>
    `${caregiver.certification} - ${caregiver.currentStage.name}`;

  applyFilters = (caregivers: CaregiverTableDataType[]): CaregiverTableDataType[] => {
    const filters: ((val: CaregiverTableDataType) => boolean)[] = [];
    const freeTextSearch = this.filters.freeText;
    if (freeTextSearch !== null) {
      filters.push((caregiver) =>
        caregiver.searchName.some((name) => name.includes(freeTextSearch.toLocaleLowerCase()))
      );
    }

    const currentStageFilter = this.filters.stages;
    if (currentStageFilter !== null && currentStageFilter.length > 0) {
      filters.push((caregiver) =>
        currentStageFilter.some(
          ({ id }) => this.stages.at(id)?.label === this.hashCaregiverStage(caregiver)
        )
      );
    }

    const certificationFilter = this.filters.certifications;
    if (certificationFilter.length > 0) {
      filters.push((caregiver) =>
        certificationFilter.some(
          ({ id }) => caregiver.certification === this.agencyCertifications.at(id)?.label
        )
      );
    }

    if (!this.filters.dateRange[0].isAfter(this.filters.dateRange[1])) {
      const startDateFilter = LocalDateTime.from(nativeJs(this.filters.dateRange[0]));
      const endDateFilter = LocalDateTime.from(nativeJs(this.filters.dateRange[1]));
      filters.push((caregiver) => {
        const startedAt = LocalDateTime.from(nativeJs(moment(caregiver.startedAt)));
        return !startedAt.isBefore(startDateFilter) && !startedAt.isAfter(endDateFilter);
      });
    }

    const filterFn = filters.reduce(
      (prev, acc) => chainBooleanFn(prev, acc),
      () => true
    );

    return caregivers.filter(filterFn);
  };

  onDateRangeChanged = (startDate: Moment, endDate: Moment) => {
    this.filters.dateRange = [startDate, endDate];
    this.applyTableData();
  };

  formatCaregiverRows = (
    caregiverStatistics: HROnboardingStatistics[]
  ): CaregiverTableDataType[] => {
    const data: CaregiverTableDataType[] = [];

    for (const caregiver of caregiverStatistics) {
      const caregiverDetails = this.caregiversMap[caregiver.caregiverId as unknown as number];
      const namesPremutations = heapsPermute([
        caregiverDetails.firstName.toLocaleLowerCase(),
        caregiverDetails.middleName?.toLocaleLowerCase(),
        caregiverDetails.lastName.toLocaleLowerCase(),
      ]).map((per) =>
        per.filter((x): x is NonNullable<typeof x> => x !== null && x !== undefined).join(" ")
      );
      data.push({
        ...caregiver,
        ...caregiverDetails,
        searchName: namesPremutations,
      });
    }

    return data;
  };

  joinArray = <T>(arr: T[], separator: string): string => {
    return arr.join(separator);
  };

  openAgencyChat = (id: CaregiverId) => {
    this.$rootScope.openAgencyChat(id);
  };

  goToProfile = (id: CaregiverId) => {
    this.$rootScope.openCaregiverModal(
      id,
      assertDefined(this.caregiversMap[id as unknown as number], "caregiver")
    );
  };
}

export const onboardingComponent: angular.IComponentOptions = {
  templateUrl: "admin/modules/onboarding/components/onboarding/onboarding.component.html",
  controller: Controller,
  controllerAs: "ctrl",
  bindings: {},
};
